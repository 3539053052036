<template>
	<a-modal v-model="show" title="学习信息" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6">
					<a-form-model-item label="所属类别" label-position="top">
						<a-select v-model="formData.categoryId" placeholder="请选择所属类别">
							<a-select-option v-for="category in categoryList" :key="category.id" :value="category.id">{{ category.name
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="标题" label-position="top">
						<a-input v-model="formData.title" placeholder="请输入标题"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="描述" label-position="top">
						<a-input v-model="formData.description" placeholder="请输入描述"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="6">
					<a-form-model-item label="获得积分" label-position="top">
						<a-input v-model="formData.grade" type="Number" placeholder="请输入获得积分"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="点击积分" label-position="top">
						<a-input v-model="formData.enterGrade" type="Number" placeholder="请输入点击积分"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="观看时长（单位：s）" label-position="top">
						<a-input type="Number" v-model="formData.time"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="是否发布" label-position="top">
						<a-switch v-model="formData.issue"></a-switch>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="是否为视听学习" label-position="top">
						<a-switch v-model="formData.audiovisual"></a-switch>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<up-img ref="upimg" @success="e => formData.thumb = e" :thumb="formData.thumb"></up-img>
				</a-col>
			</a-row>
			<a-form-model-item label="内容" label-position="top" prop="content">
				<editor :modelVal="formData.content" v-model="formData.content" />
			</a-form-model-item>
		</a-form-model>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import editor from '@/components/component/editor.vue';
import upImg from '@/components/common/upImg.vue';
export default {
	components: { editor, upImg },
	data() {
		return {
			show: false,
			loading: false,
			categoryList: [],
			formData: {
				categoryId: '',
				content: '',
				description: '',
				grade: 1,
				id: 0,
				issue: true,
				thumb: '',
				time: 60,
				title: '',
				audiovisual: false,
				enterGrade: 1
			},
			formRule: {}
		};
	},
	methods: {
		...apiUtil,
		_show(id) {
			this.show = true;
			this.getCategoryList();
			this.$nextTick(() => {
				if (id) {
					utils.showSpin();
					this.http
						.get('/platform/study/detail/' + id)
						.then(ret => {
							if (ret.code == 200) {
								utils.closeSpin();
								this.formData = ret.data;
							}
						})
						.catch(err => {
							utils.closeSpin();
							console.log(err);
						});
				} else {
					this.formData.categoryId = '';
					this.formData.content = '';
					this.formData.description = '';
					this.formData.grade = 1;
					this.formData.id = 0;
					this.formData.issue = true;
					this.formData.thumb = '';
					this.formData.time = 60;
					this.formData.title = '';
					this.formData.audiovisual = false;
					this.formData.enterGrade = 1;
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					// console.log(this.formData.managerDepIds); return;
					utils.showSpin();
					request.post('/platform/study/save', this.formData).then(ret => {
						this.$refs.formRef.resetFields();

						// console.log(ret)
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.show = false;
								this.$emit('success');
							});
						}
					});
				}
			});
		},
		// 获取分类数据
		getCategoryList() {
			this.loading = true;
			this.http
				.get('/platform/study/category')
				.then(ret => {
					if (ret.code == 200) {
						this.categoryList = ret.data;
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
